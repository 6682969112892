.backgroundimg {
    background-image: url(../images/mrecruit-18.png);
    background-size: cover;
    height: 74.5rem;
    margin-left: -1%;
    overflow-x: hidden;
}
body{
    overflow-x: hidden;
}
.mrecruitheadingfpass {
    position: absolute;
    left: 44.12%;
    top: 20.63%;
    bottom: 73.19%;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 39.39px;
    line-height: 48px;
    color: #E6E5E5;
}
.usermgmttxt{
    cursor: pointer; 
}
.emailidtop{
    margin-top: 15%;
}
.adduser{
    background-color: #EAF4FF !important;
    border: 0.5px solid #A0BED8 !important;
    color: black !important;
    font-size: 13px !important;
}
.jceysh {
background-color: #C0DEFF !important;
}
#actual-btn, #actual-btnn {
    display: inline-block;
    color: #fff;
    padding: 0.5rem;
    font-family: "Poppins Light";
    border-radius: 0.3rem;
    cursor: pointer;
}
.lbRsZk {
    font-size: 14px !important;
    font-weight: bold !important;
}
#actual-btn {
    background-color: #4293fe;
    margin-top: 1rem;
}
.regbtn{
    background-color: #4293FE;
    font-weight: bold;
    font-family: "Poppins Light" !important;
    width: 94%;
    margin-top: 10%;
}
.gSiYIW {
    background-color: #4293FE !important;
    font-family: "Poppins Bold" !important;
   
    
}
.dcSDAM {
    background-color: #4293FE !important;
    color: white !important;
}
.kPhfsN {
   
    color: white !important;
    font-family: "Poppins Bold";
}
.josgRv {
    

   font-family: "Poppins Light" !important;
   font-weight: bold !important;
  
}
.foSLBr {
color: white !important;
}
.iCZCva {
    color: white !important;
}
.jZUfIC{
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
   
}

.byYfeS{
    background-color: #C0DEFF !important;
}
.cjNoMj{
background-color: white !important;
}

.adduser::placeholder {
    color: black !important;
}
.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 30px;
    margin-top: 20%;
}
.ft-modal {
	top: 5%;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
        z-index: 9999;	
        overflow: auto;
	position: fixed;
        visibility: hidden;
	margin-top: -250px;
	background: rgba(0,0,0,.8);
	
	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.data-table-extensions > .data-table-extensions-filter {
   display: none !important;
}
.jMdbjj {
display: none !important;
}
.data-table-extensions > .data-table-extensions-action > .dropdown button {
font-size: 18px !important;
border: 1px solid !important;
}
.App_marginBottom1__1onRp {
    margin-bottom: -1.5rem !important;
}
.data-table-extensions > .data-table-extensions-action > button.download::before{
    content: "Export" !important;
    font-family: "Poppins Bold";
}
.data-table-extensions {
    padding: 0% !important;
}
.data-table-extensions > .data-table-extensions-action > button {
    float: right;
    /* display: block; */
    width: 95px !important;
    height: 37px !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgb(66, 147, 254) !important;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 0.3rem;
    outline: none;
    margin-right: 0.3rem;
    color: white;
}
.data-table-extensions > .data-table-extensions-action > button.print::before {
    content: "Print" !important;
    font-family: "Poppins Bold";
   
}
.ft-modal:target {
	opacity: 1;
	margin-top: 0px;
	visibility: visible;	
}
 .updatebtn{
        background-color: #4293FE;
        font-weight: bold;
        font-family: "Poppins Light" !important;
        margin-top: 10%;
        font-size: 13px;
 }
 .applicantmenu{
    background: #4293FE;
    padding: 5%;
    color: white;
    width: 132%;
    margin-left: -16%;
    font-family: 'Poppins Bold';
    font-size: 16px;
    margin-top: -11%;
    border-bottom: 1px solid black;
    cursor: pointer;
 
 }
 .applicantmenunotactive{
    padding: 5%;
    width: 132%;
    margin-left: -16%;
    font-family: 'Poppins Bold';
    font-size: 16px;
    border-bottom: 1px solid;
    cursor: pointer;
 }
 .applicanticon{
    border: 3px solid;
    padding: 3%;
    border-radius: 25px;
    font-size: 14px;
 }
 .applicanticonnonactive{
    border: 3px solid black;
    padding: 3%;
    border-radius: 25px;
    font-size: 14px;
 }
.ft-modal-content {
	
    padding: 25px;
	background: #FFF;
	max-width: 90%	;
    margin: 70px auto;
	position: relative;
	border-radius: 8px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
 

.ft-modal-close {
	top: 15px;
	right: 40px;
	float: right;
	position: absolute;
    font-size: 28px;
    
}
.ft-modal-close:hover{
   color: rgb(0, 147, 69);
}
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 21px;
    left: 1px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}
  
  input:checked + .slider {
    background-color: #2ab934;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on, .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 15px;
    font-family: "Poppins Bold";
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}
  /* Rounded sliders */

.usermanagementtxt {
    position: absolute;
    height: 39px;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 39px;
    color: #000000;
    border-bottom: 1px solid;
    width: inherit;
}
.toggle {
    --width: 50px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);
  
    display: inline-block;
    cursor: pointer;
  }
  
  .toggle__input {
    display: none;
  }
  
  .toggle__fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #EAF4FF;
    transition: background 0.2s;
    margin-top: 1em;
    margin-left: 2em;
  }
  
  .toggle__input:checked ~ .toggle__fill {
    background:#dddddd;
  }
  
  .toggle__fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: linear-gradient(90deg, #010D53 0%, #000A48 100%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
  }
  
  .toggle__input:checked ~ .toggle__fill::after {
    transform: translateX(var(--height));
  }
  
.mrecruittxtbelow{
    position: absolute;
    left: 66.99%;
    right: 27.79%;
    top: 83.74%;
    bottom: 10.01%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #E6E5E5;
}
.rb-tabs .rb-tabs-header .rb-tabs-active-item {
    height: 4px !important;
}
.bg-success {
    background-color: blue!important;
}
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
    font-size: 16px !important;
    font-family: "Poppins Bold" !important;
    margin-left: -1% !important;
    margin-top: 1% !important;
    font-weight: bold !important;
    
}

.iqVbfb{
    border-radius: 6px !important;
}
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active {
    font-weight: bold !important;
    color:#4293FE !important;
    
}
.adduserinputbox{
    color: black !important;
}
.passvalidtext{
    width: 60%;
    color: red;
    font-family: 'Poppins Bold';
    font-size: 11px;
    line-height: 12p
}
.forgotpasswordbox{
        border-radius: 25px;
        position: absolute;
        left: 25.74%;
        right: 25.74%;
        top: 17.11%;
        bottom: 17.11%;
        background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
        opacity: 0.56;
}

  .kNNa-dS  {
    display: flex;
    flex-direction: column;
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
  
}
  .useraddbtn{
    width: 55%;
    text-decoration: underline;
    font-size: 16px;
    font-family: "Poppins Light";
    font-weight: bold;
  }
  .nettxt{
    
    font-size: 18px;
    font-family: "Poppins Light";
    font-weight: bold;

  }
  .globeicon{
    font-size: 25px;
    color: #4293FE;
    margin-top: 5%;
    
  }
  .webtxt{
    margin-left: 5%;
    font-size: 16px;
    font-family: "Poppins Light";
    font-weight: bold;
    color: #4293FE;
    vertical-align: text-bottom;
  }
  .sidebar a {
    display: block;
    color: black !important;
    padding: 6% 0% 5% 5%;
    text-decoration: none;
    font-weight: bold;
    font-family: "Poppins Light";
  
   border-right: 2px solid #f1f1f1;
  }
  .sidebar a.access {
    background-color: #4293FE;
    color: white !important;
    /* margin-top: 18%; */
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    font-family: "Poppins Light";
}
.usermgmtmenu{
    cursor: pointer;
    text-decoration: underline !important;
    text-underline-offset: 5px;
}
.plussymbolone{
    float: right;
    padding-right: 8%;
    font-size: 38px;
    margin-top: -6%;

}
.plussymboltwo{
    float: right;
    padding-right: 8%;
    font-size: 38px;
    margin-top: -6%;

}
  .sidebar a.active {
    background-color: #4293FE;
    color: white !important;
    font-size: 18px;
    font-weight: bold;
    font-weight: 700;
    padding-left: 5%;
    font-family: "Poppins Light";
    border-right: 2px solid #4293FE;
  }
  
 /* .sidebar a:hover:not(.active) {
    background-color: #4293FE;
    color: white !important;
    font-weight: bold;
    font-family: "Poppins Light";
  }*/
  

  .accesstbl{
    background-color:#C0DEFF !important;
  }
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 414px) {
    .sidebar a {
      float: none;
    }
    .sidebar a.active {
        background-color: #4293FE;
        color: white !important;
        margin-top: 7%;
        font-size: 18px;
        font-weight: bold;
        font-family: "Poppins Light";
    }
    .useraddbtn {
        width: 12%;
        text-decoration: underline;
        font-size: 16px;
        font-family: "Poppins Light";
        font-weight: bold;
        display: block;
    }
    div.content {
        padding: 3% 16px;
        height: 40em;
        overflow-x: scroll;
        overflow-y: scroll;
        position: none !important;
    
      }
}

.mrecruittxtbelowfp{
    position: absolute;
left: 67.21%;
right: 27.58%;
top: 78.92%;
bottom: 18.83%;

font-family: 'Poppins Light';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #E6E5E5;
}
.createnewpassbox{
position: absolute;
left: 25.74%;
right: 25.74%;
top: 13.53%;
bottom: 13.53%;
background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
opacity: 0.56;
border-radius: 25px;
}
.createnewpasstxt{
 position: absolute;
left: 67.43%;
right: 27.36%;
top: 82.66%;
bottom: 15.1%;
font-family: 'Poppins Light';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #E6E5E5;
}
.recruiterdashboard {
    position: absolute;
    /* width: 102px; */
    height: 39px;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
}
.ceodashboard {
    position: absolute;
    /* width: 102px; */
    height: 39px;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 39px;
    color: #000000;
}
.emailvalidation{
    color: white;
    font-family: 'Poppins Bold';
    margin-top: 2%;
    margin-bottom: -6%;
}
.passwordvalidation {
    color: white;
    font-family: 'Poppins Bold';
    margin-top: 2%;
    margin-bottom: -6%;
    margin-left: 27%;
}
.usernamedashboard{
    position: absolute;
    width: 102px;
    height: 39px;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: #000000;
    padding: 8%;
}
.passemailtext{
    position: absolute;
    left: 37.11%;
    top: 31.98%;
    bottom: 68.88%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16.94px;
    line-height: 21px;
    color: #E6E5E5;
}
.passwordvalidationtxt{
    color: white;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
}
.toggle {
    background: none;
    border: none;
    color: white;
    /* display: none; */
    font-size: 1.em;
    font-weight: 600;
    /* padding: 0.5em; */
    position: unset;
    right: 0.75em;
    top: 2.25em;
    z-index: 9;
   
  }
  hr {
    /* margin-top: 1rem; */
    margin-bottom: 2rem !important;
    border: 0;
    border-top: 2px solid black !important;
}
  .confirmotptxt{
    position: absolute;
left: 44.13%;
right: 46.26%;
top: 32.11%;
bottom: 64.75%;

font-family: 'Poppins Light';
font-style: normal;
font-weight: 600;
font-size: 16.94px;
line-height: 21px;
/* identical to box height */

text-align: center;

color: #E6E5E5;
  }
  .otpbox{
    position: absolute;
left: 25.71%;
right: 25.76%;
top: 16.29%;
bottom: 23.77%;
border-radius: 20px;
background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
opacity: 0.56;
  }
.otpmrecruitheading{
 position: absolute;
left: 43.95%;
right: 43%;
top: 23.62%;
bottom: 69.21%;
font-family: "Poppins Light";
font-style: normal;
font-weight: 800;
font-size: 39.39px;
line-height: 48px;
/* identical to box height */
color: #E6E5E5;

  }
.returntohomepagetxt{
    text-align: center;
   font-family: "Poppins Light" !important;
    color: #6FBF2A !important;
    font-weight: 400;
    font-size: 16.03px;
    cursor: pointer;
    text-decoration: none !important;
}
.resetpasswordtext{
    position: absolute;
    left: 38.18%;
    right: 39.16%;
    top: 28.31%;
    bottom: 60.56%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16.94px;
    line-height: 21px;
    text-align: center;
    color: #E6E5E5;
}
.passwordmtop{
    margin-top: 10%;
}
.mrecruitheadinguser {
    position: absolute;
    left: 43.34%;
    top: 12.63%;
    bottom: 73.19%;
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 39.39px;
    line-height: 48px;
    color: #E6E5E5;
}
.userdetailsheadingdetails{
    position: absolute;
    left: 45.11%;
    top: 20.98%;
    bottom: 68.88%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16.94px;
    line-height: 21px;
    color: #E6E5E5;
}
  
.backtologinpage{
    margin-left: 3%;
    font-family: "Poppins Light";
    text-decoration: underline;
}
.zmfZH {
color: white !important;
font-size: 11px !important;
}
.gdRcOB {
    color: white !important;
    font-size: 11px !important;  
}
.dJjWAM:hover {
    color: white !important;
    font-size: 11px !important;  
}
a {
    color: white !important;

}
input.form-control.xxx {
    color: black !important;
    background-color: #e8e8e8 !important;
}
.dJshAA {

    font-family: 'Poppins Bold' !important;
}

ainteclogo{
    padding: 3.5%;
}
text {
    font-family: "Poppins Light" !important;
    /* margin-top: 9%; */
    font-weight: bold;
}
.showpasswordtext{
    color: white;
   font-family: "Poppins Light" !important;
    margin-left: 3%;
    vertical-align: text-bottom;
    font-size: 11px;
}
.loginbox{
    position: absolute;
    left: 25.74%;
    right: 25.74%;
    top: 8.59%;
    bottom: 11.59%;
    border-radius: 25px;
    background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
    opacity: 0.56;

}
.dailybtn{
    
   
    width: 6em;
    background: hsl(214deg 99% 63%);
    color: white;
    padding: 2%;
    font-family: "Poppins Bold";
    text-align: center;
    cursor: pointer;

    
}
.weeklybtn{
    
    background-color: hsl(0deg 0% 100%);
    width: 6em;
    color:black;
    padding: 2%;
    font-family: "Poppins Bold";
    text-align: center;
    cursor: pointer;
   
}
.monthlybtn{
   
    background-color: hsl(0deg 0% 100%);
    width: 6em;
    color:black;
    padding: 2%;
    font-family: "Poppins Bold";
    text-align: center;
    cursor: pointer;
}
.btngroup{
    margin-top: 3%;
    
}
table.tbl{
    border-collapse: inherit !important;
}
.questionmarkicon{
    float: right;
    padding-top: -13%;
    padding: 5%;
    font-size: 20px;
}
.clientsnumber{
    padding-top: 2%;
    font-size: 45px;
}
.box1{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    width: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
   text-align: center;
   font-family: 'Poppins Bold';

}
.box3{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    width: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
   text-align: center;
   font-family: 'Poppins Bold';
}
.box4{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    width: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
    text-align: center;
    font-family: 'Poppins Bold';
}
.box5{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    width: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
    text-align: center;
    font-family: 'Poppins Bold';

   
}
.box6{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    width: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
    text-align: center;
    font-family: 'Poppins Bold';

   
}
#actual-btnn {
    background-color: #4293fe;
    margin-top: -0.2rem;
}
.box2{
    display: inline-block;
    background-color: hsl(204deg 79% 32%);
    height: 12rem;
    border-radius: 15px;
    color: white;
    margin: 1% 0% 2% 3%;
    font-family: 'Poppins Bold';

    width: 12rem;
    text-align: center;
}
.box7{
    width: 28%;
    height: 23rem;
    box-shadow: 2px 1px 11px 2px;
    background-color: white;
    display: inline-block;
    margin: 1% 0% 1% 3%;
    border-radius: 10px;
    margin-bottom: 10%;
}
.box8{
    width: 91%;
    height: 40rem;
    box-shadow: 2px 1px 11px 2px;
    background-color: white;
    /* display: inline-block; */
    margin: -7% 0% 1% 3%;
    border-radius: 10px;
    margin-bottom: 10%;
}
.questionmarkiconchat {
    float: right;
    padding-top: -13%;
    padding: 2%;
    font-size: 21px;
    color: hsl(204deg 79% 32%);;
}
.onboardingtext{
    font-family: "Poppins Bold";
    font-size: 20px;
}
.passwordtext{
    color: white;
   font-family: "Poppins Light" !important;
    font-size: 11px;
}
.mrecruitheading{
    position: absolute;
    left: 43.34%;
    
    top: 17.63%;
    bottom: 73.19%;
    
    font-family: 'Poppins Bold';

    font-style: normal;
    font-weight: 800;
    font-size: 39.39px;
    line-height: 48px;
    /* identical to box height */
    
    
    color: #E6E5E5;
}
.mrecuitsubheading{
    position: absolute;
    left: 40.11%;
    top: 25.98%;
    bottom: 68.88%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16.94px;
    line-height: 21px;
    color: #E6E5E5
}
.userdetailsheading{
    position: absolute;
    left: 45.11%;
    top: 25.98%;
    bottom: 68.88%;
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16.94px;
    line-height: 21px;
    color: #E6E5E5
}

.usernamebox{
    background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
    border: 2px solid white;
   color: white;
  font-family: "Poppins Light" !important;
}
.usernamebox :hover{
    background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
    border: 2px solid white;
   color: white;
  font-family: "Poppins Light" !important;
}
 input, optgroup, textarea {
    box-shadow: none !important;
    margin: 0;
    font-family: "Poppins Light" !important;
    border: 2px solid white;
  
}
::-webkit-input-placeholder {  /* Chrome/Opera/Safari */
    color: #fff !important;
    font-family: "Poppins Light" !important;
  }
  .forgotpasswordtext{
   font-family: "Poppins Light" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 11.87px;
    line-height: 14px;
    cursor: pointer;
    color: #83ff18;
    text-decoration: underline;
  }
  .loginbtn{
    width: 100%;
    font-family: "Poppins Light";
    color: white;
    background: #6FBF2A;
    
    cursor: pointer;
    text-align: center;
  }
  .loginbtn :hover{
    width: 100%;
    font-family: "Poppins Light";
    color: white;
    background: #6FBF2A;
    
    cursor: pointer;
    text-align: center;
  }
 
  .btn:hover {
    color: white;
    text-decoration: none;
}
.notamember{
   color: white;
   font-family: "Poppins Light";
   font-size: 16px;
   font-weight: 400;
}
.registertxt{
    font-family: "Poppins Light";
    color: #6FBF2A !important;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1.7px;
    text-underline-offset: 4px
}
.dashboardheader{
    height: 5rem;
    background-color: #000b4b;
   
}
.dashboardlogo{
    padding: 1.6%;
    
}
.dashboardmenu{
    display: inline-flex;
    color: white;
    list-style: none;
    vertical-align: sub;
    font-family: "Poppins Light";
    font-weight: bold;
}
.litext {
    text-decoration: underline;
    text-decoration-color:white;
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
}
.socialWrap {
    display: inline;
    float: right;
    /* padding-right: 12%; */
    padding-top: 1%;
    font-size: 30px;
    color: white;
    width: 20%;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    border-radius: 5px;
    min-width: 13em;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    margin-left: -4em;
    height: 8rem;
    color: black;
    font-size: 14px;
    padding-top: 0.5%;
    font-family: "Poppins Light";
    /* text-align: center; */
    cursor: pointer;
    font-weight: bold;
    padding-left: 0.3%;
    padding-bottom: 5.2%;
}
.changeprofilepic{
    cursor: pointer;
    color:black !important;
    text-decoration: none !important;
}
.changeimg{
    border-radius: 50%;
    width: 35px;
    margin-left: 7%;
    cursor: pointer;
    height: 37px;
}
.masterdatalocation{
    font-family: "Poppins Light";
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;

}
.cfvfJY {
background-color: #4293fe !important;
color: white !important;
font-family: "Poppins Bold" !important;
}
.submenuglobalsetting{
    margin-left: 8%;
    line-height: 25px;
    margin-top: -2%;
    font-family: "Poppins Light";
    font-weight: 600;
    cursor: pointer;
}
 .mastersettingmenus{
    width: 18.7em;
    background: #D9D9D9;

 }
.username{
    font-size: 13px;
    font-family: "Poppins Light";
    position: inherit;
    margin-left: -15%;
    vertical-align: middle;
    font-weight: bold;
}
.icondashboard{
    margin-left: 8%;
    cursor: pointer;
    width: 75%;
} 
  .recruitersheading{
    text-align: center;
    padding-top: 3%;
    font-family: 'Poppins Bold';
    padding-left: 3%;
  }
  .dashboardsubheading{
    background-color: rgb(235, 243, 255);
    height: 100rem;
   
  }
  
  .deliveryleadselect{
    width: 100%;
    padding: 2%;
  }
  .selectbtn{
    margin-left: -4%;
    margin-top: 3%;
  }
  @media only screen and (max-width: 4220px) {
    .file-box {
        display: inline-block;
        width: 38.5em !important;
        padding: 5px 0px 5px 5px;
        box-sizing: border-box;
        height: calc(2.3rem - 2px);
        background-color: #EAF4FF ;
        border: 0.5px solid #A0BED8;
        border-radius: 4px;
      }
      
    .sidebar {
        margin: 0;
        padding: 0;
        width: 245px;
        background-color: #f1f1f1;
        position: fixed;
        margin-top: 0%;
        padding-bottom: 2%;
        margin-bottom: 2%;
        height: 100%;
        overflow: hidden;
        
      }
    div.content {
        margin-left: 13.5%;
        padding: 1% 1%;
        height: 70em;
        overflow-x: scroll;
        overflow-y: scroll;
        width: -webkit-fill-available;
        margin-top: 4%;
      }
    .forgotpasswordtext {
        margin-left: 6% !important;
    }
    .inputbox{
        margin-top: 13% !important;
    }
    .userinputbox {
        margin-top: 13% !important;
    }
    .toggle {
        margin-left: -5% !important;
    }
  }
  @media only screen and (max-width: 1920px) {
    #data_tble{
        width: 133em;
        overflow-x: scroll;
    }
  }
  @media only screen and (max-width: 1680px) {
    #data_tble{
        width: 115em;
        overflow-x: scroll;
    }
  }
  @media only screen and (max-width: 1536px) {
    #data_tble{
        width: 102em;
        overflow-x: scroll;
    }

    .file-box {
        display: inline-block;
        width: 26.5em !important;
        padding: 5px 0px 5px 5px;
        box-sizing: border-box;
        height: calc(2.3rem - 2px);
        background-color: #EAF4FF ;
        border: 0.5px solid #A0BED8;
        border-radius: 4px;
      }
      
    .sidebar {
        margin: 0;
        padding: 0;
        width: 245px;
        background-color: #f1f1f1;
        position: fixed;
        margin-top: 4%;
        padding-bottom: 2%;
        margin-bottom: 2%;
        height: 100%;
        overflow: hidden;
        
      }
    div.content {
        margin-left: 16% !important;
        padding: 1% 16px;
        height: 40em;
        overflow-x: scroll;
        overflow-y: scroll;
        position: fixed;
        width: -webkit-fill-available;
        margin-top: 4%;
      }
    .forgotpasswordtext {
        margin-left: 0% !important;
    }
    .inputbox{
        margin-top: 9% !important;
    }
    .userinputbox {
        margin-top: 5% !important;
    }
    .toggle {
        margin-left: -6% !important;
    }
  }
  @media only screen and (max-width: 1440px) {
    div.content {
        margin-left: 17% !important;
        padding: 1% 16px;
        height: 40em;
        overflow-x: scroll;
        overflow-y: scroll;
        position: fixed;
        width: -webkit-fill-available;
        margin-top: 4%;
      }
    .inputbox{
        margin-top: 11% !important;
    }
    .userinputbox {
        margin-top: 5% !important;
    }
  }
 
  @media only screen and (max-width: 1368px) {
    .file-box {
        display: inline-block;
        width: 28.5em !important;
        padding: 5px 0px 5px 5px;
        box-sizing: border-box;
        height: calc(2.3rem - 2px);
        background-color: #EAF4FF ;
        border: 0.5px solid #A0BED8;
        border-radius: 4px;
      }
      
    .sidebar {
        margin: 0;
        padding: 0;
        width: 245px;
        background-color: #f1f1f1;
        position: fixed;
        margin-top: 4%;
        padding-bottom: 2%;
        margin-bottom: 2%;
        height: 100%;
        overflow: hidden;
        
      }
    div.content {
        margin-left: 18% !important;
        padding: 1% 16px;
        height: 40em;
        overflow-x: scroll;
        overflow-y: scroll;
        position: fixed;
        width: -webkit-fill-available;
        margin-top: 4%;
    }
    .forgotpasswordtext {
        margin-left: 0% !important;
    }
    
  }
  @media only screen and (max-width: 1600px) {
    div.content {
        margin-left: 21%;
        padding: 1% 16px;
        height: 40em;
        overflow-x: scroll;
        overflow-y: scroll;
        position: fixed;
        width: -webkit-fill-available;
        margin-top: 4%;
      }
    .forgotpasswordtext {
        margin-left: 0% !important;
    }
    .toggle {
        margin-left: -6% !important;
    }
  }
  @media only screen and (max-width: 1252px){
 div.content {
    margin-left: 24.5% !important;
    padding: 1% 16px;
    height: 40em;
    overflow-x: scroll;
    overflow-y: scroll;
    position: fixed;
    width: -webkit-fill-available;
    margin-top: 4%;
}
}
  @media only screen and (max-width: 1680px) {
    .file-box {
        display: inline-block;
        width: 28.5em !important;
        padding: 5px 0px 5px 5px;
        box-sizing: border-box;
        height: calc(2.3rem - 2px);
        background-color: #EAF4FF ;
        border: 0.5px solid #A0BED8;
        border-radius: 4px;
      }
      
    .sidebar {
        margin: 0;
        padding: 0;
        width: 245px;
        background-color: #f1f1f1;
        position: fixed;
        margin-top: 5%;
        padding-bottom: 2%;
        margin-bottom: 2%;
        height: 100%;
        overflow: hidden;
        
      }
    div.content {
        margin-left: 16%;
        padding: 1% 16px;
        overflow-x: scroll;
        overflow-y: scroll;
        position: fixed;
        width: -webkit-fill-available;
        margin-top: 5%;
        
      }
    .forgotpasswordtext {
        margin-left: 0% !important;
    }
    .toggle {
        margin-left: -6% !important;
    }
  }
@media only screen and (min-width: 319px) and (max-width: 480px){
   
    .createnewpassbox {
        left: 4.74%;
        right: 3.74%;
        top: 13.53%;
        bottom: 3.53%;
    } .forgotpasswordbox {
        left: 4.74%;
        right: 2.74%;
        top: 17.11%;
    }
    .otpbox {
        left: 6.71%;
        right: 5.76%;
        top: 16.29%;
     
    }
    .otpmrecruitheading {
        left: 28.95%;
    }
    .confirmotptxt {
        left: 37.13%;
        right: 36.26%;
        top: 33.11%;
        font-size: 15.94px;
    }
    .toggle {
        width: 26%;
        margin-left: -17%;
    }
    .ceodashboard {
        font-size: 18px;
    }
    table.tbl {
        border-collapse: inherit !important;
        margin-top: 6%;
        margin-bottom: 5%;
    }
    .box7 {
        width: 95%;
    }
    .onboardingtext {
        font-family: "Poppins Bold";
        font-size: 15px;
    }
    .dashboardsubheading {
        background-color: rgb(235, 243, 255);
        height: auto;
    }
.dashboardmenu {
    font-size: 8px;
}
.dashboardheader {
   height: 7rem; 
}
.socialWrap {
    display: contents;
    font-size: 16px;
    color: white;
}

    .passwordvalidtxt{
        margin-left: 10%;
        margin-top: 3%;
    
    }
    .passwordmtop{
        margin-top: 14em;
    }
    .emailidtop{
        margin-top: 18em;
    }
    .passemailtext {
        left: 11.11%;
        top: 31.98%;
        font-size: 14px;
    
    }
    .resetpasswordtext {
        left: 20.18%;
        right: 16.16%;
        top: 28.31%;
        font-size: 13.94px;
    }
    .mrecruitheadingfpass {
        left: 33.34%;
        top: 22.63%;
      
    }
    .showpasswordmargin{
        margin-left: 10%;
    }
    .forgotpasswordisplay{
        display: none;
    }
   .passwordtxt{
    margin-left: 10%;
    width: 80%;
    line-height: 11px;
    margin-top: 3%;
   }
    .mrecruitheadinguser {
        position: absolute;
        left: 35.34%;
        top: 20.63%;
        bottom: 73.19%;
        font-family: 'Poppins Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 25.39px;
        line-height: 48px;
        color: #E6E5E5;
    }
    .userdetailsheadingdetails {
        position: absolute;
        left: 36.11%;
        top: 26.98%;
        bottom: 68.88%;
       font-family: "Poppins Light" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 16.94px;
        line-height: 21px;
        color: #E6E5E5;
    }
    .loginboxuser{
        margin-top: 40%;
    }
    .passvalidtext {
        width: 67%;
        color: red;
        font-family: 'Poppins Bold';
        font-size: 11px;
        line-height: 12p;
        /* margin-left: 8%; */
        padding-left: 10%;
        padding-top: 4%;
        padding-bottom: 3%;
    }
 .backgroundimg {
        height: 57rem;
    }
    .loginbox {
        position: absolute;
        left: 5.74%;
        right: 3.74%;
        top: 20.59%;
        bottom: -11.41%;
        border-radius: 25px;
        background: linear-gradient(90deg,#407dcf,#224d96);
        opacity: 0.56;
    }
    .inputbox {
        margin-top: 57%;
        width: 102%;
        padding-left: 4%;
    }
    .usernamebox {
        background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
        border: 2px solid white;
        color: white;
       font-family: "Poppins Light" !important;
        width: 80%;
        margin-left: 10%;
    }
    .forgotpasswordtext {
       font-family: "Poppins Light" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 11.87px;
        line-height: 14px;
        color: #83ff18;
        cursor: pointer;
        margin-left: 62% !important;
        cursor: pointer;
        text-decoration: underline;
    }
    .loginbtn {
        width: 80%;
        font-family: "Poppins Light";
        color: white;
        background: #6FBF2A;
        cursor: pointer;
        text-align: center;
        margin-left: 10%;
    }
    .mrecruitheading {
        position: absolute;
        left: 32.34%;
        top: 23.63%;
        bottom: 73.19%;
        font-family: "Poppins Light";
        font-style: normal;
        font-weight: 800;
        font-size: 25.39px;
        line-height: 48px;
        color: #E6E5E5;

    }
    .mrecuitsubheading {
        position: absolute;
        left: 19.11%;
        top: 28.98%;
        bottom: 68.88%;
       font-family: "Poppins Light" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12.94px;
        line-height: 21px;
        color: #E6E5E5;
    }
 
    .showpwdsection{
        margin-left: 9%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
    .dashboardmenu {
        display: inline-flex;
        color: white;
        list-style: none;
        vertical-align: sub;
        font-family: "Poppins Light";
        font-size: 10px;
        font-weight: bold;
    }
    .socialWrap {
        display: contents;
        float: right;
        font-size: 17px;
      
    }
    .monthlybtn {
        padding: 0% !important;
    }
    .dailybtn {
        padding: 0% !important;
    }
    .weeklybtn{
        padding: 0% !important;
    }
    .onboardingtext {
        font-family: "Poppins Bold";
        font-size: 11px;
    }
    .loginboxuser {
        margin-top: 10em;
    }
    .forgotpasswordisplay {
        display: none;
    }
    .emailidtop {
        margin-top: 20em;
    }
    .mrecruitheadingfpass {
        left: 38.34%;
        top: 16.63%
    }
    .passemailtext {
        left: 27.11%;
        top: 26.98%;
    }
    .resetpasswordtext {
        left: 36.18%;
        right: 39.16%;
        top: 23.31%;
    }
    .mrecruitheading {
        position: absolute;
        left: 38.34%;
        top: 10.63%;
        bottom: 73.19%;
        font-family: "Poppins Light";
        font-style: normal;
        font-weight: bold;
        font-size: 29.39px;
        line-height: 48px;
        color: #E6E5E5;
    }
    .mrecuitsubheading {
        position: absolute;
        left: 34.11%;
        top: 15.98%;
        bottom: 68.88%;
       font-family: "Poppins Light" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14.94px;
        line-height: 21px;
        color: #E6E5E5;
    }
    .inputbox {
        margin-top: 15%;
    }
    .loginbox {
        position: absolute;
        left: 17.74%;
        right: 14.74%;
        top: 8.59%;
        bottom: 8.59%;
        border-radius: 25px;
        background: linear-gradient(90deg, #407DCF 0%, #224D96 100%);
        opacity: 0.56;
    }
    .backgroundimg {
   height: 64.5rem;
    }
}
.inputboxxx{
    color: white !important;
}
.editaccess{
    color: black !important;
}
.blankcheckbox{
    color: white;
    border: 2px solid black;
    cursor: pointer;
    font-size: 12px;
    border-radius: 2px;
cursor: pointer;
    vertical-align: inherit;
}
.solidcheckbox{
    color: rgb(66, 147, 254);
    cursor: pointer;
    vertical-align: inherit;
    font-size: 18px;
}
.teamview {
    color: rgb(66, 147, 254) !important;
    font-family: 'Poppins Bold';
    text-align: left;
    padding: 10%;
    vertical-align: sub;
    display: flex;
}
.hWVghp {
    font-weight: 400;
    display: block !important;
}
.gvYYnf {
    font-weight: 400;
    display: block !important;
}
.lbaWmG {
    padding: 5px !important;
    opacity: 1 !important;
}
.gqmYNu {
    color: white !important;
}
.dvsxvu{
    color: white !important;
}
.cHhxkP {
    color: white !important;
    opacity: 1 !important;
}
.jonxvd {
    padding: 5px !important;
    color: white !important;
    opacity: 1 !important;
}
.hBoifs {
    padding: 2px !important;
    color: white !important;

}
.bqEkvt{
    color: white !important;
}
.kcxzBB{
    color: white !important;
}
.iconsclient{
   
    font-size: 25px;
    margin-top: 2.2%;
}

.inputfile-box {
    position: relative;
    margin-top: 1%;
  }
  
  .inputfile {
    display: none;
    color: black !important;
  }
  
  .container {
    display: inline-block;
    width: 100%;
  }
  
 
  .file-button {
    background: rgb(66, 147, 254);
    padding: 6px;
    position: absolute;
    border: 1px solid;
    top: 0px;
    right: 0px;
    color: white;
    font-family: "Poppins Light";
    font-weight: bold;
    font-size: 15px;
  }
  .subdeleteicon{
    width: 13%;
    margin-left: 10%;
    cursor: pointer;
    margin-top: 7%;
  }
  
.bCyoYR {
background-color: white !important;
color: white !important;
}
.eCuCWJ{
    background-color: #C0DEFF !important;
}
.hHhPCq{
    background-color: #C0DEFF !important;
}

.dropdown-contentt {
    position: absolute;
    background-color: #f9f9f9;
    border-radius: 5px;
    min-width: 13em;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    /* margin-left: -4em; */
    height: auto;
    color: black;
    font-size: 14px;
    padding-top: 0.5%;
    font-family: "Poppins Light";
    /* text-align: center; */
    cursor: pointer;
    font-weight: bold;
     padding: 3%;
}
.checkboxstyle{
    cursor: pointer;
    font-size: 14px;
    color: white;
    border: 3px solid black;
    border-radius: 5px;
    vertical-align: text-bottom;

}
.ddrTJQ{
    min-height: 1px !important;
}
.boyBjT {
    min-height: 1px !important;
}
.checkboxstyle{
    vertical-align: -webkit-baseline-middle;
    transform: scale(1.3);
}
.checkboxstyletwo {
    vertical-align: baseline;
    transform: scale(1.3);
}
.App_pageBox__T3B0E{
    margin-bottom: 6em !important;
}
.tagtojobtxt{
    font-family: "Poppins Light";
font-weight: bold;
font-size: 16px;
cursor: pointer;
color: #007bff !important;
text-decoration: none !important;
margin-left: 3%;
}
/* .kRMhgC{
    display: none !important;
} */
.graycolorcircle{
    color: #b1aaaa;
    font-size: 20px;
    margin-left: -1em;
    margin-right: 1em;
    vertical-align: middle;
    cursor: pointer;
}
.lightyellocolorcircle{
    color: rgb(1 255 21);
    font-size: 20px;
    margin-left: -1em;
    margin-right: 1em;
    vertical-align: middle;
    cursor: pointer;
}
.redcolorcircle{
    color: red;
    font-size: 20px;
    margin-left: -1em;
    margin-right: 1em;
    cursor: pointer;
    vertical-align: middle;
   
    
}
.dropdown-toggle {
   
    color: transparent !important;
}
.bluecolorcircle{
    color: blue;
    font-size: 20px;
    margin-left: -1em;
    margin-right: 1em;
    vertical-align: middle;
    cursor: pointer;
}
.dropdown-menu.show {
    margin-top: -2.5em !important;
    margin-left: 1em !important;
   
}
.kNrZse{
    background-color: #c0deff !important;
}

.tooltipp {
    position: relative;
    display: inline-block;
  }
  
  .tooltipp .tooltiptext {
    visibility: hidden;
    width: 90px;
    width: 87px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    margin-left: -2.8em;
    margin-top: 14%;
    font-family: "Poppins Light";
    font-size: 11px;
    font-weight: bold;
  }
  .tooltipp .tooltiptext::after{
    content: " ";
    position: absolute;
    left: -10px;
    top: 4px;
    border-top: 8px solid transparent;
    border-right: 12px solid black;
    border-left: none;
    border-bottom: 9px solid transparent;
  }
  .tooltipp:hover .tooltiptext {
    visibility: visible;
  }
  .tooltippp {
    position: relative;
    display: inline-block;
  }
  
  .tooltippp .tooltiptextt {
    visibility: hidden;
    width: 150px;
    background-color: #f9f9f9;
    /* text-align: center; */
    border-radius: 6px;
    /* padding: 4px 0; */
    position: absolute;
    z-index: 1;
    margin-left: -0.8em;
    margin-top: 14%;
    font-family: "Poppins Light";
    line-height: 20px;
    font-size: 13px;
    font-weight: bold;
    padding-left: 0.5em;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  }
  
  .tooltippp:hover .tooltiptextt {
    visibility: visible;
  }
  .pipelinearrow::after{
    content: " ";
    position: absolute;
    right: 10em;
    bottom: -12px;
    border-top: 15px solid blueviolet;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
  .pipelinebtn{
    background-color: blueviolet;
    border-radius: 20px;
    font-family: "Poppins Light";
    font-weight: bold;
    width: 82%;
    color: white !important;
    margin-left: 4%;
  }
  .submissionbtn{
    background-color:rgb(66, 147, 254);
    border-radius: 20px;
    font-family: "Poppins Light";
    font-weight: bold;
    width: 65%;
    color: white !important;
  }
  .submissionbtnn{
    background-color:rgb(66, 147, 254);
    border-radius: 20px;
    font-family: "Poppins Light";
    font-weight: bold;
    width: 90%;
    color: white !important;
  }
  .submissionbtn::after{
    content: " ";
    position: absolute;
    right: 19em;
    bottom: -12px;
    border-top: 15px solid rgb(66, 147, 254);
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
  .submissionbtnn::after {
    content: " ";
    position: absolute;
    right: 10em;
    bottom: -12px;
    border-top: 15px solid rgb(66, 147, 254);
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
}
  .jZUfIC div:first-child {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 16em !important;
    font-size: 13px !important;
}

.lbRsZk div:first-child {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 16em !important;
    font-size: 12px !important;
}
.userlogo {
    width: 85%;
    border-radius: 50%;
    border: 2px solid blue;
    cursor: pointer;
}
.clientlogo{
    width: 85%;
    border-radius: 4px;
    border: 2px solid blue;
    padding: 11%;
    cursor: pointer;
  }
  .App_pageBox__T3B0E{
    margin-left: -1% !important;
  }
  

@media only screen and (max-width: 1280px){
    div.content {
margin-top: 6%;
margin-left: 19% !important;
    }
    .sidebar{
        margin-top: 6%;
    }
}
.ioaxeM {
    padding: 2px;
    color: white !important;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 1 !important;
}
.bqEkvt {
    color: white !important;
    font-family: "Poppins Bold" !important;
}
.bBmgpw {
color: white;
font-family: "Poppins Bold" !important;
}


.rdw-editor-wrapper {
    box-sizing: content-box;
    border: 2px solid !important;
    padding: 1% !important;
    height: 12em !important;
    overflow: hidden !important;
}
.rdw-dropdown-selectedtext {
color: black !important;
}
.hfbDlp {
    
    color: white !important;
    font-family: 'Poppins Bold' !important;
}
.rdw-image-wrapper {
   
    display: none !important;
}
.rdw-link-wrapper {
    display: none !important;
}
.rdw-embedded-wrapper{
    display: none !important;
}
.subtext{
    color: rgb(0, 123, 255) !important;
    text-decoration: none !important;
}
.jobdetailstxt{
    font-family: "Poppins Light";
    font-weight: bold;
    font-size: 14px;
    background: rgb(0, 123, 255);
    color: white;
    padding: 1%;
    /* border: 2px solid; */
    /* border-radius: 12px; */
    width: auto;
    text-align: center;
    /* height: 3.5em; */
}
.arrow-right{
    width: 0px;
    height: 0px;
    border-top: 22px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 23px solid rgb(0, 123, 255);
}
.dashboardtable1{
    background-color: #fff;
    border: none;
    box-shadow: 0 0 5px #a2a2a2;
    margin: 1rem;
    padding: 10px;
    border-radius: 2px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.swal2-title {

font-size: 1.5em !important;
}
.keyskills{
    font-family: "Poppins Light";
    margin-top: 5%;
    font-size: 13px;
    vertical-align: sub;
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 1.1em;
    font-weight: bold;
}
.keyskills::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: transparent;
    margin-top: 2%;
}

/* Track */
.keyskills::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.keyskills::-webkit-scrollbar-thumb {
  background: transparent; 
}

/* Handle on hover */
.keyskills::-webkit-scrollbar-thumb:hover {
  background: transparent
}

.keyskills::-webkit-scrollbar-thumb:horizontal{
    background: transparent;
    border-radius: 10px;
}
.applicantname{
    font-family: "Poppins Light";
    font-weight: bold;
    color: rgb(66, 147, 254);
    text-decoration: underline;
    vertical-align: sub;
    cursor: pointer;
    font-size: 12px;
    overflow-x: scroll;
    padding-bottom: 1.1em;
}
.applicantname::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: transparent;
    margin-top: 2%;
}

/* Track */
.applicantname::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.applicantname::-webkit-scrollbar-thumb {
  background: transparent; 
}

/* Handle on hover */
.applicantname::-webkit-scrollbar-thumb:hover {
  background: transparent
}

.applicantname::-webkit-scrollbar-thumb:horizontal{
    background: transparent;
    border-radius: 10px;
}
.recruitername{
    font-family: "Poppins Light";
    font-weight: bold;
    display: flex;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
}
.recruitername::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: transparent;
    margin-top: 2%;
}

/* Track */
.recruitername::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.recruitername::-webkit-scrollbar-thumb {
  background: transparent; 
}

/* Handle on hover */
.recruitername::-webkit-scrollbar-thumb:hover {
  background: transparent
}

.recruitername::-webkit-scrollbar-thumb:horizontal{
    background: transparent;
    border-radius: 10px;
}
.recruitername::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
  .applicantname::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
  .keyskills::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
  }
.hrnew{
    margin-bottom: 1rem !important;
}
.submissiontble{
    background-color: #fff;
    border: none;
    box-shadow: 0 0 5px #a2a2a2;
    margin: 1rem;
    padding: 10px;
    border-radius: 2px;
    width: 65%;
}
.submissiondate{
    margin-top: 4em;
    color: black;
    position: absolute;
    margin-left: 3em;
}
.kyQVCC {
   
    -webkit-justify-content: center !important;
    color: black !important;
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
}
.boyxZB {
    -webkit-justify-content: center !important;
    color: black !important;
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
}
.eRoiyn{
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
} 
.ftaatH {
    font-family: "Poppins Light" !important;
    font-weight: bold !important;
} 
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007bff; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #007bff; 
  }

  .paginate-ctn{
    display: flex;
}
.pagination {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
}
.pagination {
    display: flex; 
    list-style: none;
    border-radius: .25rem;
    background: #c0deff;
    padding: 2px;
}
/* .pagination .previous {
    display: none;
}
.pagination .next {
    display: none;
} */
.pagination .page.active, .pagination .page:hover {
    font-family: "Poppins Light";
    background-color:#007bff;
    color: #fff;
    font-weight: bold;
}
.pagination .page {
    color: white;
    padding: 7px 12px 7px 13px;
    background:#007bff;
    border-radius: 4px;
    margin: 0 4px;
    font-family: "Poppins Light";
    cursor: pointer;
    display: block;
    font-weight: bold;
}
.custom-pagination.text-center {
    font-family: 'Poppins Light';
    margin-top: 1.2%;
    font-weight: bold;
    font-size: 14px;
}

li.break-me a {
    color: #007bff !important;
    font-size: 2em;
}
li.previous.disabled a {
    color: #007bff !important;
}

li.next a {
    color: #007bff !important;
}

li.previous a {
    color: #007bff !important;
}


.submissions-checkbox{
    vertical-align: -webkit-baseline-middle;
    transform: scale(1.3);
    cursor: pointer;
    font-size: 14px;
    color: white;
    border: 3px solid black;
    border-radius: 5px;
    vertical-align: text-bottom;
    margin-left: 2em;
    margin-right: 3em;
}
.send-emailtext-applicants{
    font-family: "Poppins Light";
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    color: rgb(66, 147, 254);
    vertical-align: -webkit-baseline-middle;
    text-decoration: underline; /*15th august css*/
    margin-left: 2px;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 2px;
}
.applicant-noticeperiod-text{
    font-family: "Poppins Light";
    font-weight: bold;
    margin-left: 5%;
    font-size: 13px;
    vertical-align: text-bottom;
    vertical-align: sub;
    cursor: pointer;
}

.applicant-send-email-row{
    margin-top: -3.5em;
    margin-bottom: 1em;
}  
input.form-control.applicants-filter {
    color: black !important;
    background-color: #e8e8e8 !important;
}
input.form-control.applicants-filter::placeholder {
    color: black !important;
    font-family: "Poppins Light" !important;
    font-size: 13.5px !important;
    font-weight:500 !important;
}
input.formdate {
    color: black !important;
    font-family: "Poppins Light" !important;
    font-size: 13.5px !important;
    font-weight:500 !important;
    background-color: #e8e8e8 !important;
    height: 40px;
    border-radius: 4px;
    padding-left: 10px;
    cursor: pointer;
    width: 11em;
}
input.formdate::placeholder {
color: black !important;
}
.margin-left-css{
    margin-left: -1.5%;
}
.applicant-search-btn {
    background-color: #4293FE;
    font-weight: bold;
    font-family: "Poppins Light" !important;
    margin-top: 2%;
    font-size: 13px;
    color: white;
    width: -webkit-fill-available;
    height: 36.5px;
    margin-left: 7%;
}
.applicant-reset-btn {
    color: black;
    margin-top: 2%;
    background-color: rgb(243, 240, 240);
    border: 1px solid;
    font-weight:500;
    width: -webkit-fill-available;
    height: 36.5px;
    margin-left: 7%;
    font-size: 13px;
    font-family: "Poppins Light" !important;
    border-radius: 4px;
}
.nodatafound-msg{
    font-family: "Poppins Light" !important;
    font-weight:bold;
    font-size: 15px;
    margin-left: 46em;
}