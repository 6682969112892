@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("./font/Montserrat-Regular.ttf")

}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url("./font/Inter-Light.otf")
}
@font-face {
  font-family: 'Inter Bold';
  src: local('Inter'), url("./font/Inter-Bold.otf")
}
@font-face {
  font-family: 'Poppins Light';
  src: local('Inter'), url("./font/Poppins-Light.ttf")
}
@font-face {
  font-family: 'Poppins Bold';
  src: local('Inter'), url("./font/Poppins-Bold.ttf")
}
@font-face {
  font-family: 'Poppins';
  src: local('Inter'), url("./font/Poppins-Regular.ttf")
}