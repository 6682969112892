.header {
  overflow: hidden;
  background-color: #e0000e;
  padding: 20px 10px;
  text-align: center;
}

.header a {
 
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
  
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
  font-family: cursive;
  font-weight: bold;
  color: white;
}


.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a.logo {
   font-size: 14px;
  }
  
  .header-right {
    float: none;
  }
}

.topnav {
  overflow: hidden;
  margin-top: 1%;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 40rem !important;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  height: 38rem !important;
}
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 15px;
  font-family: cursive;
  font-weight: bold;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
 
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 17rem !important;
  }
}
